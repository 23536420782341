import React from "react";
import PropTypes from "prop-types";

import configs from "../../utils/configs";
import { ReactComponent as HmcLogo } from "../icons/HmcLogo.svg";
import { isHmc } from "../../utils/isHmc";
import { useLogo } from "../styles/theme";

export function AppLogo({ className, forceConfigurableLogo }) {
  let logo = useLogo();

  const currentUrl = window.location.href;
  const specialUrls = [
    "https://nztoverse.com/aKFJimN/",
    "https://nztoverse.com/LNt3CPs/",
    "https://nztoverse.com/Q8RGvUd/",
    "https://nztoverse.com/dGsyXdm/"
  ];

  // Check if currentUrl starts with any of the specialUrls
  const isSpecialUrl = specialUrls.some(url => currentUrl.startsWith(url));

  if (isSpecialUrl) {
    logo = "https://nztoverse-1-assets-4fce4460.s3.us-west-2.amazonaws.com/assets/images/logo_g.png";
  }

  if (isHmc() && !forceConfigurableLogo) {
    return <HmcLogo className="hmc-logo" />;
  } else {
    return <img className={className} alt={configs.translation("app-name")} src={logo} />;
  }
}

AppLogo.propTypes = {
  className: PropTypes.string,
  forceConfigurableLogo: PropTypes.bool
};